import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Nav from 'react-bootstrap/Nav'
import { Container } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";
import {Trans, Link, useTranslation, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';


export default () => {
  const {languages, originalPath} = useI18next();
  const context = React.useContext(I18nextContext);
  const taal = context.language
    const data = useStaticQuery(graphql`
    query {
        wp {
          websiteSettings {
            algemeneInfo {
              mailadres
              telefoonnummer
              logoWit {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: TRACED_SVG
                      quality: 100
                      formats: [WEBP])
                    
                  }
                }
              }
              logoKleur {
                sourceUrl
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 160
                      placeholder: TRACED_SVG
                      quality: 100
                      formats: [WEBP])
                    
                  }
                }
              }
            }
          }
        }
      }                       
            `)
            const {t} = useTranslation()
            const [navBackground, setNavBackground] = useState('1')
            const navRef = React.useRef()
            navRef.current = navBackground
            useEffect(() => {
                const handleScroll = () => {
                    const show = window.scrollY > 100
                    if (show) {
                        setNavBackground('0')
                    } else {
                        setNavBackground('1')
                    }
                }
                document.addEventListener('scroll', handleScroll)
                return () => {
                    document.removeEventListener('scroll', handleScroll)
                }
            }, [])

        var navStyle = 'navbar-transparant'    
        if (navRef.current === '1') {
            var navStyle = 'navbar-transparant'
        } else { navStyle = 'navbar-full'}

    return (
        <div>
        <Navbar expand="lg" fixed="top" className={navStyle}>
        <Container css={css`max-width: var(--screen-width); margin: auto auto; padding: 0px 0px;`} >
          <Navbar.Brand>
            {navRef.current==='1' && <GatsbyImage
            image={data.wp.websiteSettings.algemeneInfo.logoWit.localFile.childImageSharp.gatsbyImageData}
            alt={data.wp.websiteSettings.algemeneInfo.logoWit.altText}
            layout="fixed"
          />}   
            {navRef.current==='0' && <GatsbyImage
            image={data.wp.websiteSettings.algemeneInfo.logoKleur.localFile.childImageSharp.gatsbyImageData}
            alt={data.wp.websiteSettings.algemeneInfo.logoKleur.altText}
            layout="fixed"
            
          />}
          </Navbar.Brand>
          <Navbar.Toggle />
            <Navbar.Collapse  id="responsive-navbar-nav" bg="light" variant="light" className="justify-content-end">
            <Nav className="justify-content-end">
        <NavDropdown active="true" title={t('Diensten')}>
          <NavDropdown.Item><Link to="/diensten#particulieren" ><Trans>Particulieren</Trans></Link></NavDropdown.Item>
          <NavDropdown.Item><Link to="/diensten#bedrijven" ><Trans>Bedrijven</Trans></Link></NavDropdown.Item>
        </NavDropdown>
        <Link to="/over-ons" ><Navbar.Text><Trans>Over Ons</Trans></Navbar.Text></Link>
        <Link to="/nieuws" ><Navbar.Text><Trans>Nieuws</Trans></Navbar.Text></Link>
        <Link to="/jobs" ><Navbar.Text><Trans>Jobs</Trans></Navbar.Text></Link>
        <Link to="/contact" ><button><Trans>Contact</Trans></button></Link>
        <NavDropdown active="true" css={css`margin-left: 2rem;`} title={taal}>
        {languages.map((lng) => (
          <NavDropdown.Item css={css`width: 2rem;`}>
                                <div key={lng}>
                                  <Link  to={originalPath} language={lng}>
                                    {lng}
                                  </Link>
                                </div>
                                </NavDropdown.Item>
                              ))}
        </NavDropdown>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>


        <Navbar expand="lg" fixed="top" className="navbar-full-mobile">
        <Container css={css`max-width: var(--screen-width); margin: auto auto; padding: 0px 0px;`} >
          <Navbar.Brand>
<GatsbyImage
            image={data.wp.websiteSettings.algemeneInfo.logoKleur.localFile.childImageSharp.gatsbyImageData}
            alt={data.wp.websiteSettings.algemeneInfo.logoKleur.altText}
            layout="fixed"
            css={css`margin-left: 1rem; margin-top: 0.5rem;`}
            
          />
          </Navbar.Brand>
          <Navbar.Toggle css={css`margin-right: 1rem; margin-top: 0.5rem;`}/>
            <Navbar.Collapse className="justify-content-end">
            <Nav className="justify-content-end">
            <Link className="mobilenavlink" to="/diensten" css={css`margin-top: 2rem;`} ><Navbar.Text><Trans>Diensten</Trans></Navbar.Text></Link>
        <Link className="mobilenavlink" to="/over-ons" ><Navbar.Text><Trans>Over Ons</Trans></Navbar.Text></Link>
        <Link className="mobilenavlink" to="/nieuws" ><Navbar.Text><Trans>Nieuws</Trans></Navbar.Text></Link>
        <Link className="mobilenavlink" to="/jobs" ><Navbar.Text><Trans>Jobs</Trans></Navbar.Text></Link>
        <Link className="mobilenavlink" to="/contact" ><Navbar.Text><Trans>Contact</Trans></Navbar.Text></Link>
        <Navbar.Text>            <div className="contacttable mobile-col" css={css`display: flex; width: 70%`}>
                    <div className="contactitem"><FaPhoneAlt css={css`color: var(--accent-color); margin-right: 10px; overflow: hidden;`}/><a href={`tel:${data.wp.websiteSettings.algemeneInfo.telefoonnummer}`}>{data.wp.websiteSettings.algemeneInfo.telefoonnummer}</a></div>
                    <div className="contactitem"><FaEnvelope css={css`color: var(--accent-color); margin-right: 10px;`} /><a href={`mailto:${data.wp.websiteSettings.algemeneInfo.mailadres}`}>{data.wp.websiteSettings.algemeneInfo.mailadres}</a></div>
                </div></Navbar.Text>
            </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        </div>
   )
    } 
